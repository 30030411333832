<nav class="sidebar" [ngClass]="{sidebarPushRight: isActive, collapsed: collapsed}">
    <div class="list-group">
        <!-- <a routerLink="/dashboard" [routerLinkActive]="['router-link-active']" class="list-group-item">
            <i class="fa fa-fw fa-dashboard"></i>&nbsp;
            <span>{{ 'Dashboard' | translate }}</span>
        </a>
        <a [routerLink]="['/charts']" [routerLinkActive]="['router-link-active']" class="list-group-item">
            <i class="fa fa-fw fa-bar-chart-o"></i>&nbsp;
            <span>{{ 'Charts' | translate }}</span>
        </a> -->
        <a [routerLink]="['/home']" [routerLinkActive]="['router-link-active']" class="list-group-item">
            &nbsp;
            <span>{{ 'Home' }}</span>
        </a>




         <a [routerLink]="['/about']" [routerLinkActive]="['router-link-active']" class="list-group-item">
            
            <span>{{ 'About Us' }}</span>
        </a>
        <a [routerLink]="['/contact']" [routerLinkActive]="['router-link-active']" class="list-group-item">
        &nbsp;
            <span>{{ 'Contact Us'  }}</span>
        </a>
        <a [routerLink]="['/login']" [routerLinkActive]="['router-link-active']" class="list-group-item">
        &nbsp;
            <span>{{ 'Log in'  }}</span>
        </a>
        <a [routerLink]="['/signup']" [routerLinkActive]="['router-link-active']" class="list-group-item">
            &nbsp;
            <span>{{ 'Register'  }}</span>
        </a>
       
       
      

        <!-- <div class="header-fields">
            <a (click)="rltAndLtr()" class="list-group-item">
                <span><i class="fa fa-arrows-h"></i>&nbsp; RTL/LTR</span>
            </a>
            <div class="nested-menu">
                <a class="list-group-item" (click)="addExpandClass('languages')">
                    <span><i class="fa fa-language"></i>&nbsp; {{ 'Language' | translate }} <b class="caret"></b></span>
                </a>
                <li class="nested" [class.expand]="showMenu === 'languages'">
                    <ul class="submenu">
                        <li>
                            <a href="javascript:void(0)" (click)="changeLang('en')">
                                {{ 'English' | translate }}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="changeLang('fr')">
                                {{ 'French' | translate }}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="changeLang('ur')">
                                {{ 'Urdu' | translate }}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="changeLang('es')">
                                {{ 'Spanish' | translate }}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="changeLang('it')">
                                {{ 'Italian' | translate }}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="changeLang('fa')">
                                {{ 'Farsi' | translate }}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" (click)="changeLang('de')">
                                {{ 'German' | translate }}
                            </a>
                        </li>
                    </ul>
                </li>
            </div>
            <div class="nested-menu">
                <a class="list-group-item" (click)="addExpandClass('profile')">
                    <span><i class="fa fa-user"></i>&nbsp; John Smith</span>
                </a>
                <li class="nested" [class.expand]="showMenu === 'profile'">
                    <ul class="submenu">
                        <li>
                            <a href="javascript:void(0)">
                                <span><i class="fa fa-fw fa-user"></i> {{ 'Profile' | translate }}</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <span><i class="fa fa-fw fa-envelope"></i> {{ 'Inbox' | translate }}</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <span><i class="fa fa-fw fa-gear"></i> {{ 'Settings' | translate }}</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/login']" (click)="onLoggedout()">
                                <span><i class="fa fa-fw fa-power-off"></i> {{ 'Log Out' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </div>
        </div> -->
    </div>
    <div class="toggle-button" [ngClass]="{collapsed: collapsed}" (click)="toggleCollapsed()">
        <i class="fa fa-fw fa-angle-double-{{collapsed?'right':'left'}}"></i>&nbsp;
        <span>{{ 'Collapse Sidebar'  }}</span>
    </div>
</nav>
