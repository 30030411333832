import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Item } from 'src/app/Entity/item';
@Injectable({
    providedIn: 'root'
})
export class AppService {
    private zomenuUser = environment.zomenuUser;
    constructor(private http: HttpClient) { }
    item: Item = new Item();

    putItem(item) {
        this.item = item;
        console.log("this.item:" + this.item);
    }
    getitem() {
        return this.item;
        this.item = new Item();
    }



}