import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { HotelService } from 'src/app/service/hotel.service';
import { Hotel } from 'src/app/Entity/hotel';
import { Observable, interval } from 'rxjs';
import { environment } from 'src/environments/environment';

import { RestaurantDetailsComponent } from './zomenu_user/restaurant-details/restaurant-details.component';

import { AppService } from 'src/app/service/app.service';
import { Item } from './Entity/item';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  openorderpadfirsttime = 0;
  public isCollapsed = true;
  image = environment.imageurl;
  item: Item = new Item();
  items = [];
  count = 1;
  granttotal = 0;
  price = 0;
  currencysymbol = '';
  keyword = 'hotelname';
  hotel: Hotel = new Hotel();
  hotels1 = [];
  currentpage: number = 1;
  total_pages: number;
  datahotel: any;
  hotels = [];
  hotels2 = [];
  //  itemcount: number = 1;
  // public itemcount: boolean[] = [];
  route: string;

  private router1: Router;

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  constructor(
    location: Location,
    router: Router,
    private hotelservice: HotelService,
    private appservice: AppService,
    private routers: Router,
    private location1: Location
  ) {



    // console.log('countload:' + this.count);
    interval(250).subscribe((x) => {
      // console.log('local:' + localStorage.getItem('count'));
      // console.log('count:' + this.count);

      if (this.count == +localStorage.getItem('count')) {
        //   console.log('local:' + localStorage.getItem('count'));
        // console.log('count:' + this.count);

        //console.log('count is same :');
        this.getitemlist();
      }
    });

    router.events.subscribe((val) => {
      if (location.path() != '') {
        this.route = location.path();
        // console.log("route:"+this.route);
      } else {
        //this.route = "Home";
        // console.log("route:" + this.route);
      }
    });
  }

  getitemlist() {
    //console.log('in getitemlist');
    this.item = this.appservice.getitem();
    //console.log(':' + this.item.text);
    //  console.log(':' + this.item.id);
    // console.log('inapp item:' + JSON.stringify(this.item));

    let text = 'Add';

    if (this.item.id != null) {
      this.count++;
      console.log('countin getlist:' + this.count);
      if (this.item.text.match(text)) {
        console.log('removetext:' + this.item.text);
        // console.log('add text:' + JSON.stringify(this.item));
        for (let index = 0; index < this.items.length; index++) {
          let item = this.items[index];

          if (item.id == this.item.id) {
            console.log('id:' + this.item.id);
            item.text = 'Add';

            let sum = this.granttotal - item.orderpadprice;
            this.granttotal = sum;
            console.log('this.granttotal:' + this.granttotal);
            this.items.splice(index, 1);
            // console.log('removeitems:' + JSON.stringify(this.items));
            // this.removeItemFromOrderPad(index, item);
            //this.item.id = null;
            return this.granttotal;
            break;
          }
        }
      } else {
        //   console.log('add text:' + JSON.stringify(this.item));
        console.log('add text:' + this.item.text);
        this.item.itemcount = 1;
        let price = +this.item.price;
        this.item.orderpadprice = price;
        //console.log('in:');
        this.items.push(this.item);
        //console.log('out:');
        let firstadditem = +localStorage.getItem('count');
        this.items.forEach(element => {
          console.log('items:' + JSON.stringify(element));
        });


        if (firstadditem == 1) {
          this.toggle();
        }
      }
    }

    // if (this.item.id != null) {
    //     this.count++;
    //     JSON.stringify(this.item)
    //     //console.log("inapp item:" + JSON.stringify(this.item));
    //     this.item.itemcount = 1;
    //     let price = +this.item.price;
    //     this.item.orderpadprice = price;
    //     //  console.log("in:");
    //     this.items.push(this.item);
    // }
    //console.log("inapp items:" + JSON.stringify(this.items));
    if (this.items.length != 0) {
      if (this.items.length == 1) {
        this.totalPrice();
        // this.isCollapsed = false;
        //this.toggle();
      } else {
        let price = +this.item.price;
        this.granttotal = this.granttotal + price;
      }
    }

    this.item = new Item();
  }
  toggle() {
    // this.openorderpadfirsttime++;
    // if (this.openorderpadfirsttime == 1) {
    //     this.clearorderpad();
    // }
    // toggle based on index
    this.isCollapsed = !this.isCollapsed;
    //  this.hideRuleContent[listindex] = !this.hideRuleContent[listindex];
    // this.hideRuleContent[index] = false;
  }
  clearorderpad() {
    for (let item of this.items) {
      item.text = 'Add';
    }

    this.items = [];
    this.totalPrice();
  }

  removeItemFromOrderPad(i: number, item): void {
    console.log('item.orderpadprice:' + item.orderpadprice);
    console.log('this.granttotal:' + this.granttotal);

    this.granttotal = this.granttotal - item.orderpadprice;
    console.log('this.granttotal:' + this.granttotal);
    item.text = 'Add';
    this.items.splice(i, 1);
    // return this.granttotal;
    // this.totalPrice();
  }

  totalPrice() {
    this.granttotal = 0;
    for (let item of this.items) {
      if (this.granttotal != 0) {
        let price = +item.price;
        this.granttotal = this.granttotal + price;
      }
      if (this.granttotal == 0) {
        let price2 = +item.price;
        this.currencysymbol = item.currenysymbol;
        this.granttotal = price2;
      }
      //  console.log("granttotal:" + this.granttotal);
    }
    return this.granttotal;
  }

  openoerder() {
    console.log('jhfjd');
    this.clearorderpad();
  }
  addSameItem(item) {
    item.itemcount++;
    let price = +item.price;
    this.granttotal = this.granttotal + price;
    item.orderpadprice = (price * item.itemcount).toString();
  }

  removeSameItem(item) {
    if (item.itemcount > 1) {
      item.itemcount--;
      let price = +item.price;
      this.granttotal = this.granttotal - price;
      item.orderpadprice = item.orderpadprice - price;
    }
  }


  changepage(page: number) {
    // console.log("page:" + page);
    this.hotels1 = [];
    this.hotelservice.getUserHotelsForPage(page).subscribe(
      (data) => {
        this.datahotel = data['data'];
        this.total_pages = this.datahotel['total_pages'];
        this.currentpage = this.datahotel['page'];
        this.hotels1 = this.datahotel['results'];
        JSON.stringify(this.hotels1);
        //console.log("hotels1:" + JSON.stringify(this.hotels1));
      },
      (error) => {
        console.log(error['error']);
      }
    );
  }
  selectEvent(item: any) {
    //  console.log("item:" + item);
    this.hotel = item;
    this.goToRestaurantDetails(this.hotel);
  }


  goToRestaurantDetails(hotel) {
    localStorage.setItem('hotelid', hotel.id);
    // const heroId = hero ? hero.id : null;
    const hotelid = hotel.id;
    // console.log("khh");
    // this.router.navigate(['/' + hotel.hotelname + '/' + hotel.id]);
    this.routers.navigate(['/details'], { queryParams: { id: hotelid } });

  }
  ngOnInit() {
    console.log('isCollapsed:' + this.isCollapsed);
    // interval(250).subscribe((x) => {
    //   console.log('window.scrollY:' + window.scrollY);
    //   console.log('window.innerHeight:' + window.innerHeight);
    // });

    this.location1.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.routers.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else window.scrollTo(0, 0);
      }
    });

// 
this.hotelservice.getUserHotels().subscribe(
  (data) => {
    this.datahotel = data['data'];
    this.total_pages = this.datahotel['total_pages'];
    this.currentpage = this.datahotel['page'];
    this.hotels1 = this.datahotel['results'];
    JSON.stringify(this.hotels1);
    //   console.log("hotels1:" + JSON.stringify(this.hotels1));

    for (
      let pageno = this.currentpage;
      pageno <= this.total_pages;
      pageno++
    ) {
      //  console.log("pageno:" + pageno);
      this.hotelservice.getUserHotelsForPage(pageno).subscribe(
        (data) => {
          this.datahotel = data['data'];
          this.hotels2 = this.datahotel['results'];
          //console.log("hotels1:"+JSON.stringify(this.hotels1));
          for (let h of this.hotels2) {
            this.hotels.push(h);
            // console.log("h:"+JSON.stringify(h));
          }
          // console.log("h:" + JSON.stringify(this.hotels));
        },
        (error) => {
          console.log(error['error']);
        }
      );
    }
  },
  (error) => {
    console.log(error['error']);
  }
);





  }



}
