import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/zomenu_components/_modal';
import { Location } from "@angular/common";
import { HotelService } from 'src/app/service/hotel.service';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


    title = 'appBootstrap';
    currentpage: number = 1;
    pageSize = 20;
    total_pages: number;
    public isCollapsed = true;
    route: string;
    username = localStorage.getItem('Username');
    public pushRightClass: string;
    opendropdown = false;

    hotels1 = [];
    datahotel: any;
    

    constructor(location: Location, private translate: TranslateService, private hotelservice: HotelService, public router: Router, private ms: ModalService) {
        router.events.subscribe(val => {

            this.route = location.path();
            // console.log("headerroute:" + this.route);

        });
        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 992 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
    }
    onclose() {
        this.isCollapsed = true;
    }
    opentoggle() {
        this.opendropdown = true;
        console.log(this.opendropdown);
    }


    //=============================================  Modal=================================//

    openModal(id: string) {
        this.isCollapsed = true;
        this.ms.open(id);
    }

    closeModal(id: string) {
        this.ms.close(id);
    }
    //=============================================  Modal=================================//
    ngOnInit() {
        this.pushRightClass = 'push-botttom';
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onLoggedout() {
        localStorage.removeItem('token');
    }

    changeLang(language: string) {
        this.translate.use(language);
    }

    // inculde 
    
}
