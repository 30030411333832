<!-- <button class="btn btn-outline-primary" ngbDropdownToggle>Toggle dropdown</button> -->
<!-- <div ngbDropdown class="d-inline-block dropdown">
                     <button class="navbar-toggler" ngbDropdownToggle type="button">
        <i class="fa fa-bars text-muted" aria-hidden="true"></i>
    </button>
                    <div ngbDropdownMenu style="width: 321px;">
                        <button class="dropdown-item" [routerLink]="['/home']">Home</button>
                        <button class="dropdown-item" [routerLink]="['/about']">About Us </button>
                        <button class="dropdown-item" [routerLink]="['/contact']">Contact Us </button>
                         <button class="dropdown-item" (click)="openModal('zomenubusiness')">Go to Zomenu Business </button>
                    </div>
                </div> -->
<!-- <nav class="navbar navbar-expand-lg fixed-top text-center">
 
 <a class="navbar-brand" [routerLink]="['/home']"><b>Z<span style="color: red;">o</span>Menu</b></a>
 <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
    <i class="fa fa-bars text-muted" aria-hidden="true"></i>
  </button>
    <div  class="collapse navbar-collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav ml-auto">
      <li class="nav-item "> <a class=" nav-link mt-1 afternavitems" (click)="onclose()" [routerLink]="['/home']"><i class="fa fa-home" aria-hidden="true"></i>   Home</a></li>
      <li class="nav-item "> <a class=" nav-link mt-1 afternavitems"(click)="onclose()" [routerLink]="['/about']">About Us</a></li>
      <li class="nav-item "> <a class=" nav-link mt-1 afternavitems"(click)="onclose()" [routerLink]="['/contact']"><i class="fa fa-phone" aria-hidden="true"></i>   Contact Us</a></li>
      <li class="nav-item "> <a class=" nav-link mt-1 afternavitems"(click)="onclose()" [routerLink]="['/userhotels']"><i class="fa fa-cutlery" aria-hidden="true"></i>   Restaurants</a></li>
 
              <li class="nav-item">    <a class=" nav-link mt-1"(click)="onclose()"(click)="openModal('zomenubusiness')"  ><i class="fa fa-handshake-o" aria-hidden="true" style='color:red'></i>   Go to Zomenu Business</a></li>
                 <jw-modal id="zomenubusiness">
                  <div class="modal-header text-center">
                       <h2>Go to Zomenu Business</h2>
                        <button type="button" class="close" aria-label="Close" (click)="closeModal('zomenubusiness')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                  </div>
             <div class="modal-body  text-center" style="padding: 0rem;">
           <div class="row">
              <div class="col-md-6">

                <button class="btn btn-dark  btn-lg  btn-block" [routerLink]="['/login']"  >Log in</button></div>
                <div class="col-md-6">
                <button class="btn btn-dark  btn-lg btn-block" [routerLink]="['/signup']">Register</button> </div>
            
           </div>
 
            </div>
</jw-modal>
           
        </ul>
    </div>
</nav> -->
<!-- Header section starts from here -->
<header class="fixed-top">
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <a href="./" class="navbar-brand">
        <img src="assets/img/logo.png" alt="Logo" width="120" height="16">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#divNavbar"
        aria-controls="divNavbar" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="divNavbar">
        <ul class="list-unstyled navbar-nav ml-auto">
          <li class="nav-item">
            <!-- [ngClass]="route=='/home'? 'nav-link active' : 'nav-link'"  -->
            <a [ngClass]="route=='/home'? 'nav-link active' : 'nav-link'" [routerLink]="['/home']">Home</a>
          </li>
          <li class="nav-item">
            <a [ngClass]="route=='/about'? 'nav-link active' : 'nav-link'" [routerLink]="['/about']">About</a>
          </li>
          <!-- <li class="nav-item">
            <a [ngClass]="route=='/contact'? 'nav-link active' : 'nav-link'"
              [routerLink]="['/contact']">Contact</a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</header>