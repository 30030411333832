export class Item {
  //id:number;
  id: string;
  title: string;
  description: string;
  price: string;
  tag: string;
  currenysymbol: string;
  itemimg: string;
  position: number;
  foodtype: number[];
  hotelname: string;
  itemcount: number = 1;
  orderpadprice: number;
  text: string;
  itemimgurl: string;
  slide_image: Array<{ id: string; itemimg: string, itemimgurl: string }>;
}
export class EditItem {
  id: string;
  title: string;
  description: string;
  price: string;
  tag: string;
  itemimg: string;
  currenysymbol: string;
  itemcount: number = 1;
  itemimgurl: string;
  slide_image: Array<{ id: string; itemimg: string, itemimgurl: string }>;
}
