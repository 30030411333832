
//class for save hotel
export class Hotel {
    //id:number;
    id: string;
    hotelname: string;
    hoteladdress: string;
    city: string;
    state: string;
    country: string;
    country_name_code: string;
    zipcode: string;
    contactno: string;
    contact_no_country_code: string;
    contact_no_name_code: string;
    opentime: string;
    closetime: string;
    latitude: number;
    longitude: number;
    facebook_url: string;
    description: string;
    hotelimgurl: string;
    iconimgurl: string;
    daytime: Array<{
        id: string, startday: string, endday: string, startdayposition: number,
        enddayposition: number, opentime: string, closetime: string
    }>;

    slide_image: Array<{ id: string, hotelimg: string, hotelimgurl: string }>;
}


export class AllPropertiesHotel {
    id: string;
    hotelname: string;
    hoteladdress: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
    contactno: string;
    opentime: string;
    closetime: string;
    hotelplaceimg: string;
    icon: string;
    description: string;
    contact_no_country_code: string;
    facebook_url: string;
    hotelimgurl: string;
    iconimgurl: string;
    daytime: Array<{
        id: string, startday: string, endday: string, startdayposition: number,
        enddayposition: number, opentime: string, closetime: string
    }>;

    slide_image: Array<{ id: string, hotelimg: string, hotelimgurl: string }>;

}