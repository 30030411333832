<div class="page-wrapper" style="min-height:100%;">

    <app-header></app-header>
    <div class="content-overflow">
        <!-- <section class="home-sec1" *ngIf="route=='/home'">
            <div class="slider home-slider">
                <div class="slide">
                    <div class="slider-content">
                        <h1 class="text-uppercase wow fadeInDown">Explore food</h1>
                        <h4 class="mb-5 wow fadeInUp">Zomenu helps you find out
                            food
                            & drinks in your city.</h4>
                    </div>
                    <img src="assets/img/slide1.png" alt="slider bg1">
                </div>
                <div class="slide">
                    <div class="slider-content">
                        <h1 class="text-uppercase wow fadeInDown">Ordering made
                            easy</h1>
                        <h4 class="mb-5 wow fadeInUp">Zomenu helps you find out
                            food
                            & drinks in your city.</h4>
                    </div>
                    <img src="assets/img/slide2.png" alt="slider bg1">
                </div>
                <div class="slide">
                    <div class="slider-content">
                        <h1 class="text-uppercase wow fadeInDown">Explore food</h1>
                        <h4 class="mb-5 wow fadeInUp">Zomenu helps you find out
                            food
                            & drinks in your city.</h4>
                    </div>
                    <img src="assets/img/slide3.png" alt="slider bg1">
                </div>
            </div>
        </section>  -->

        <section class="home-sec1" *ngIf="route=='/home'">
            <div class="container">
              <div class="row">
                <div class="col-md-8 mx-auto">
                  <div class="text-center">
                    <h1>Ordering made easy</h1>
                    <h2>Zomenu helps you find out<br>food & drinks in your city</h2>
                  </div>
                  <div class="row">
                    <div class="col-lg-10 mx-auto">
                      <div class="search-form">
                        <div class="input-group">
                           

                                <ng-autocomplete class="ng-autocomplete" [data]="hotels" [searchKeyword]="keyword"
                                    placeHolder="Search Restaurant..." historyIdentifier="hotels" [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate" (selected)="selectEvent($event)"></ng-autocomplete>
                                <ng-template #itemTemplate let-item style="padding: 7px;">
                                    <div class="item">
                                        <img src="{{item.iconimgurl}}" style="height:100%; width:65px;display:
                                            block;"><span [innerHTML]="item.hotelname"></span>
                                    </div>
                                    <hr>
                                </ng-template>
                
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                                <div class="input-group-append" style="margin-left: -11px;">
                                    <button class="btn btn-secondary"><i class="fa fa-search"></i></button>
                                </div>
                            </div>
                          <!-- <input type="text" class="form-control" placeholder="Search Restaurant...">

                          <div class="input-group-append">
                            <button class="btn btn-secondary"><i class="fa fa-search"></i></button>
                          </div>
                           -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


        <!-- <select class="my-select" id="my-select" tabindex="1"
                            data-placeholder="Search Restaurant.." >
                                
                                <option ></option>
                                <option data-img-src="assets/img/restaurant1.jpg">Buca
                                Yorkville</option>
                                <option data-img-src="assets/img/restaurant1.jpg">Buca
                                Yorkville</option> 
                        </select>  -->
        <!-- <div class="container" *ngIf="route=='/index'">
            <div class="row">
                <div class="col-md-7 home-form mx-auto">
                    <div class="input-group">

                        
                        <ng-autocomplete
                            [data]="hotels"
                            [searchKeyword]="keyword"
                            placeHolder="Search Restaurant.."
                            historyIdentifier="hotels"
                            [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate"
                            (selected)="selectEvent($event)"></ng-autocomplete>
                

                        <ng-template #itemTemplate let-item>
                            <div class="item">
                                <img src="{{image}}{{item.hotelplaceimg}}"
                                    height="25" width="35">
                                <span [innerHTML]="item.hotelname"></span>
                            </div>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>



                        <div class="input-group-append">
                            <button class="btn btn-secondary rounded-0
                                border-0"><i
                                    class="fa fa-search"></i></button>
                        </div>


                    </div>
                </div>
            </div>
        </div> -->
        <router-outlet></router-outlet>
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 wow fadeInLeft" data-wow-delay="0.5s">
                        <img src="assets/img/logo-white.png" alt="logo-white" class="footer-logo">
                        <br>
                        <p style="color: white;">ZOMENU is the #1 Menu App that helps you to increase
                            your revenue by digitizing the dining, delivery &
                            pick-up experience.</p>
                        <ul class="list-unstyled footer-social">
                            <li><a href="https://www.facebook.com/Zomenu" target="_blank"><i class="fab
                                    fa-facebook-f"></i></a></li>
                            <li><a href="https://instagram.com/zomenuofficial/" target="_blank"><i class="fab
                                    fa-instagram"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCTeNYvYISk_SJEOtJNyEN2w" target="_blank"><i
                                        class="fab fa-youtube"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/zomenu" target="_blank"><i
                                        class="fab fa-linkedin-in"></i></a>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="col-lg-2 col-md-4 col-6 wow fadeInLeft">
                        <h3>Services</h3>
                        <ul class="list-unstyled">
                            <li><a href="#">Cooked</a></li>
                            <li><a href="#">Deliver</a></li>
                            <li><a href="#">Quality Foods</a></li>
                            <li><a href="#">Mixed</a></li>
                        </ul>
                    </div> -->
                    <div class="col-lg-3 col-md-4 col-6 wow fadeInRight">
                        <h3>COMPANY</h3>
                        <ul class="list-unstyled">
                            <!-- <li><a [routerLink]="['/contact']">Help Center</a></li> -->
                            <li><a [routerLink]="['/about']">About zomenu</a></li>
                            <li><a [routerLink]="['/privacy']">Privacy Policy</a></li>
                            <!-- <li><a [routerLink]="['terms-and-services']">Terms
                                    and
                                    Services</a></li> -->
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-4 wow fadeInRight" data-wow-delay="0.5s">
                        <h3>Contact</h3>
                        <ul class="list-unstyled contact-tab">
                            <!-- <li><i class="fa fa-map-marker-alt"></i> 203 Fake
                                St. Mountain View, San Francisco, California,
                                USA</li> -->

                            <li><i class="fa fa-map-marker-alt"></i>442, Maruti Plaza, Opp Vijay
                                park,Krishnanagar,Naroda, Ahmedabad - 382350,Gujarat-India.</li>
                            <!-- <li>Krishnanagar</li>
                            <li>Naroda, Ahmedabad - 382350</li>
                            <li>Gujarat-India.</li> -->
                            <!--<li><i class="fa fa-phone"></i> <a href="tel:1234567890">123 456 7890</a></li> -->
                            <li><i class="fa fa-envelope"></i> <a href="mailto:hello@zomenu.com">hello@zomenu.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

        <!-- Footer section starts from here -->
        <!-- <div id="orderPad" class="collapse order-pad">
            <div class="order-pad-close"><i class="fa fa-times"></i></div>
            <div class="card border-0 rounded-0">
                <div class="card-header rounded-0"><i class="fa fa-list-alt"></i>
                    Quck Order Pad <button type="button" class="btn
                        btn-outline-light btn-sm" (click)="clearorderpad()">Clear
                        All</button></div>
                <div class="card-body">
                    <div class="menu-item" *ngFor="let item of items; let i=
                        index;">
                        <div class="menu-thumbnail" *ngIf="(item | json) != ({}
                            | json)">
                            <div class="menu-img">
                                <i [ngClass]="item.veg_nonveg==0 ? 'food-type' :
                                    'food-type nonveg'"></i>
                                <img class="div-img"
                                    src={{image}}{{item.itemimg}}
                                    style="background-image:url({{image}}{{item.itemimg}})"
                                    alt="Pizza Image">
                            </div>
                        </div>
                        <div class="menu-details">
                            <div class="menu-title">
                                <h4>{{item.title}}<span class="badge
                                        badge-primary">{{item.tag}}</span></h4>
                                <span class="price">{{item.currenysymbol}}{{item.orderpadprice}}</span>
                            </div>
                            <div class="menu-description">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary
                                            rounded-0" type="button"
                                            (click)="addSameItem(item)">+</button>
                                    </div>
                                    <input type="text" class="form-control
                                        rounded-0 text-center"
                                        [(ngModel)]="item.itemcount" readonly>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary
                                            rounded-0" type="button"
                                            (click)="removeSameItem(item)">-</button>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-primary
                                    btn-sm" title="Remove"
                                    (click)="removeItemFromOrderPad(i,item)"><i
                                        class="fa
                                        fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right">Grand Total:<strong>{{currencysymbol}}{{granttotal}}</strong></div>
            </div>
        </div>
        <button type="button" class="btn btn-primary order-pad-btn"
            data-toggle="collapse" data-target="#orderPad"><i class="fa
                fa-list-alt"></i> Quck Order Pad</button> -->


<!-- =========================================== QUICK-ORDER-PAD  ==============================================   -->


        <div id="orderPad" class="collapse order-pad" [ngbCollapse]="isCollapsed">
            <div class="order-pad-close"><i class="fa fa-times"></i></div>
            <div class="card border-0 rounded-0">
                <div class="card-header rounded-0"><i class="fa fa-list-alt"></i>
                     Quick Order Pad <button type="button" class="btn
                        btn-outline-light btn-sm" (click)="clearorderpad()">Clear
                        All</button></div>
                <div class="card-body scroll">
                    <div class="menu-item" *ngFor="let item of items; let i=
                        index;">
                        <div class="menu-thumbnail" *ngIf="(item | json) != ({}
                            | json)">
                            <div class="menu-img">
                                <i class="food-type"></i>
                                <img class="div-img" src={{image}}{{item.itemimg}}
                                    style="background-image:url({{image}}{{item.itemimg}})" alt="Pizza Image">
                            </div>
                        </div>
                        <div class="menu-details">
                            <div class="menu-title">
                                <h4>{{item.title}}<span class="badge
                                        badge-primary">{{item.tag}}</span></h4>
                                <span class="price">{{item.currenysymbol}}{{item.orderpadprice}}</span>
                            </div>
                            <div class="menu-description">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary
                                            rounded-0" type="button" (click)="addSameItem(item)">+</button>
                                    </div>
                                    <input type="text" class="form-control
                                        rounded-0 text-center" [(ngModel)]="item.itemcount" readonly>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary
                                            rounded-0" type="button" (click)="removeSameItem(item)">-</button>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-primary
                                    btn-sm" title="Remove" (click)="removeItemFromOrderPad(i,item)"><i class="fa
                                        fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                    <!-- {{currencysymbol}} -->
                </div>
                <div class="card-footer text-right">Grand Total: <strong>{{granttotal}}</strong></div>
            </div>
        </div>



        <!-- ====================END============================ -->
        <button type="button" class="btn btn-primary order-pad-btn" (click)="toggle()"
            [attr.aria-expanded]="!isCollapsed" aria-controls="orderPad"><i class="fa
                fa-list-alt"></i> Quck Order Pad</button>
        <div class="footer-btm text-center">
            Copyright © 2020 ZOMENU. All rights reserved. | Developed and design
            by <a href="https://www.navines.com/" target="_blank">NAVINES</a>
        </div>
    </div>