// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  //  url: 'http://localhost:8080/',
  //  zomenuUser: 'http://localhost:8080/zomenu-user/',
  //  zomenuBusiness: 'http://localhost:8080/zomenu-business/',
  //  imageurl: 'http://localhost:8080/zomenu/image/',


  // url: 'http://3.7.246.128:8080/',
  // imageurl: 'http://3.7.246.128:8080/zomenu/image/',
  // zomenuUser: 'http://3.7.246.128:8080/zomenu-user/',
  // zomenuBusiness: 'http://3.7.246.128:8080/zomenu-business/',

   url: 'http://3.7.246.128:8080/test_version_2/',
   imageurl: 'http://3.7.246.128:8080/test_version_2/zomenu/image/',
  zomenuUser: 'http://3.7.246.128:8080/test_version_2/zomenu-user/',
  zomenuBusiness: 'http://3.7.246.128:8080/test_version_2/zomenu-business/',

  //Can Not modified
  apikey: 'AIzaSyBYgS0nu4yB5z5Y9goYafnc4cgFkc3v6Qs',

  //apikey: 'AIzaSyAri1VLUJ5XFyE6fGXM4K3QlPNJhAL8n5A',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
