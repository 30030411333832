import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';

const routes: Routes = [
  {
    path: 'sitemap',
    loadChildren: () =>
      import('./zomenu_user/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'details',
    // path: ':name/:hotelid',
    loadChildren: () =>
      import('./zomenu_user/restaurant-details/restaurant-details.module').then(
        (m) => m.RestaurantDetailsModule
      ),
  },
  // {
  //   path: 'contact',
  //   loadChildren: () =>
  //     import('./zomenu_user/contact/contact.module').then(
  //       (m) => m.ContactModule
  //     ),
  // },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./zomenu_user/privacy/privacy.module').then(
        (m) => m.PrivacyModule
      ),
  },
  {
    path: 'terms-and-services',
    loadChildren: () =>
      import('./zomenu_user/terms-and-services/terms-and-services.module').then(
        (m) => m.TermsAndServicesModule
      ),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./zomenu_user/404-not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
  {
    path: 'demo',
    loadChildren: () =>
      import('./zomenu_user/demo/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./zomenu_user/index/index.module').then((m) => m.IndexModule),
  },
  
  {
    path: 'about',
    loadChildren: () =>
      import('./zomenu_user/aboutus/aboutus.module').then(
        (m) => m.AboutusModule
      ),
  },
  {
    path: 'map',
    loadChildren: () =>
      import('./zomenu_user/map/map.module').then((m) => m.MapModule),
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
