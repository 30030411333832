import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HotelService {
  private zomenuUser = environment.zomenuUser;


  
  constructor(private http: HttpClient) {}

  //================ get hotels from zomenu user ==========================//
  getUserHotels() {
    let pageno = '1';
    const params = new HttpParams().set('page', pageno);
    return this.http.get(`${this.zomenuUser}` + 'hotel/hotellist', { params });
  }

  getUserHotelsForPage(pageno) {
    const params = new HttpParams().set('page', pageno);
    return this.http.get(`${this.zomenuUser}` + 'hotel/hotellist', { params });
  }

  getUserHotelById(id) {
    return this.http.get(`${this.zomenuUser}` + 'hotel/gethotel/' + id);
  }

}
