        <div class="jw-modal">
        <div class="row block">
        <div class="col-sm-3">
           
        </div>
        <div class="col-sm-6">

    <div class="jw-modal-body">
        <ng-content></ng-content>
    </div>

     </div>
        <!-- /.col-sm-4 -->
        <div class="col-sm-3">
            
        </div>
       
        <!-- /.col-sm-4 -->
    </div>
</div>
 <div class="jw-modal-background"></div>
            <!-- <div class="card text-white bgcard mb-3 text-center">
                <div class="card-body">
                   <ng-content></ng-content>
                </div>
            </div> -->
       



           